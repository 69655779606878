import http from "../http-common";

class UploadFilesService {
    upload(files) {
        let formData = new FormData();
         console.log('files : ', files);
        for (let i = 0; i < files.length; i++) {
            formData.append("uploadingFiles", files[i])
        }

        return http.post("/folder", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
    }

    getFiles(code) {
        return http.get("/folder/"+code);
    }
}

export default new UploadFilesService();
