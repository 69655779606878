import React, {Component} from 'react';
import fileImg from '../../assets/img/file.png';
import downImg from '../../assets/img/download-btn.png';
import UploadService from "../../services/uploadFilesService";
import FullPageLoader from "../fullPageLoader/FullPageLoader";
import ModalCode from "../uploadFiles/ModalCode";
import {isMobile} from "react-device-detect";


class DownloadFiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            code: '',
            files: [],
            disable: true,
            loading: false,
            show: false,
            errorCode:0,
        };
    }

    render() {
        const {files, disable, loading, show, errorCode} = this.state;

        return (
            <div >
                {loading ? <FullPageLoader/> : null}
                <p className='txt-upload'>Mentionner votre code ici pour récupérer vos fichiers :</p>
                <div className="divUpload">
                    <div className="divCode">
                        <input type="text" className="input-code" maxLength='5' onChange={this.handleChange} required/>
                    </div>
                </div>
                <div className="div-fileSize">
                    <p className="txt-fileSize">Liste des Fichiers ({files.length})</p>
                </div>
                <div className="div-fileList">
                    {files &&
                    files.map((file, index) => (
                    <div className="div-file" key={index}>
                        <div className="div-fileName">
                            <img src={fileImg} alt="file"/>
                            <div className="div-fileImage">
                                <p className="txt-fileSize">{file.title}</p>
                            </div>
                        </div>
                        {isMobile ?
                            <img className="bluredDownload" onClick={this.showModalMobile}
                                 src={downImg} alt="download"/>
                            :
                            <a href={"https://api.hetcode.com/api/fichier/" + file.id + "/" + file.source}
                               download><img  style={{cursor: 'pointer'}}
                                             src={downImg} alt="download"/></a>
                        }
                    </div>

                    ))}

                </div>

                <button
                    className="uploadBtn"
                    onClick={this.downloadFiles}
                    disabled={disable}
                >
                    Valider
                </button>
                <ModalCode show={show} successcode={errorCode} onHide={() => this.hideModal()}/>
            </div>
        );
    }

    handleChange = (e) => {
        e.target.value = e.target.value.toUpperCase();
        if (e.target.value.toString().length <= 5) {
            this.setState({
                code: e.target.value.toUpperCase(),
            });
        }
        if (e.target.value.toString().length === 5) {
            this.setState({
                disable: false
            })
        } else {
            this.setState({
                disable: true
            })
        }


    }

    downloadFiles = () => {
        this.setState({loading: true});
        let code = this.state.code;
        UploadService.getFiles(code).then((response) => {
            this.setState({
                files: response.data.fichiers,
                loading: false
            });
        }).catch(() => {
            this.showModal();
        });
    }

    showModal = () => {
        this.setState({loading: false, show: true,errorCode:2});
    };

    hideModal = () => {
        this.setState({show: false});

    };
    showModalMobile = () => {
        this.setState({loading: false, show: true,errorCode:3});
    };



}

export default DownloadFiles;
