import React, {Component} from 'react';
import Header from "../header/Header";
import CoolTabs from 'react-cool-tabs';
import UpdateFiles from "../uploadFiles/UpdateFiles";
import DownloadFiles from "../downloadFiles/DownloadFiles";

class Home extends Component {


    render() {

        return (

            <div className="home-container">
                <Header/>
                <div id="576333525" dangerouslySetInnerHTML={this.createMarkup()}>

                </div>
               <div className="coolTabs-div">
                <CoolTabs
                    tabKey={'1'}
                    style={{width: '100%', height: '100%', background: 'white', borderRadius: 10}}
                    activeTabStyle={{background: '#356AFB', color: 'white',fontSize:15,fontFamily:'Wigrum-Regular'}}
                    unActiveTabStyle={{background: 'white', color: '#A4A4A4',fontSize:15,fontFamily:'Wigrum-Regular',cursor:'pointer'}}
                    activeLeftTabBorderBottomStyle={{background: '#E1E1E1', height: 2}}
                    activeRightTabBorderBottomStyle={{background: '#E1E1E1', height: 2}}
                    tabsBorderBottomStyle={{background: '#E1E1E1', height: 2}}
                    leftContentStyle={{background: 'white'}}
                    rightContentStyle={{background: 'white'}}
                    leftTabTitle={'Uploader Fichiers'}
                    rightTabTitle={'Télécharger Fichiers'}
                    leftContent={<UpdateFiles/>}
                    rightContent={<DownloadFiles/>}
                    contentTransitionStyle={'transform 0.6s ease-in'}
                    borderTransitionStyle={'all 0.6s ease-in'}/>

            </div>
            </div>
        );
    }

    createMarkup=()=> { return {__html: ' <script type="text/javascript">\n' +
            '        try {\n' +
            '            window._mNHandle.queue.push(function (){\n' +
            '                window._mNDetails.loadTag("576333525", "300x250", "576333525");\n' +
            '            });\n' +
            '        }\n' +
            '        catch (error) {}\n' +
            '    </script> '}; };


}

export default Home;
