import React, {Component} from 'react';
import fileImg from '../../assets/img/file.png';
import deleteBtn from '../../assets/img/delete-btn.png';
import UploadService from "../../services/uploadFilesService";
import _ from 'lodash';
import ModalCode from "./ModalCode";
import FullPageLoader from "../fullPageLoader/FullPageLoader";
import {fileSizeConverter} from '../../constants/commonFunctions'

class UpdateFiles extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: [],
            sizeError:false,
            show: false,
            code:'',
            success:false,
            loading:false
        };
    }

    render() {
        const {selectedFiles,show,code,success,loading} = this.state;
        let somme =this.filesSize();
        const error = somme>5245000000 ? 'sizeError' : '';
        const classes = `txt-fileSize ${error}`

        return (

            <div >
                {loading?<FullPageLoader/>:null}
                <p className='txt-upload'>Uploader vos fichiers ici, un code vous sera généré :</p>
                <div className="divUpload">
                    <label className="filelabel">
                        <i className="material-icons">attach_file</i>
                        <span className="title">Ajouter des fichiers</span>
                        <input className="FileUpload1" id="FileInput" name="booking_attachment" type="file" multiple
                               onChange={this.selectFiles}/>
                    </label>
                </div>
                <div className="div-fileSize">
                    <p className="txt-fileSize">Liste des Fichiers ({selectedFiles.length})</p>
                    <p className={classes}>{this.totalSizeConverter(somme)} / 5 Gb</p>
                </div>
                <div className="div-fileList">
                    {selectedFiles &&
                    selectedFiles.map((file, index) => (
                    <div className="div-file" key={index}>
                        <div className="div-fileName">
                            <img src={fileImg} alt="file"/>
                            <div className="div-fileImage">
                                <p className="txt-fileSize">{file.name}</p>
                                <p className="txt-size">{fileSizeConverter(file.size)}</p>
                            </div>
                        </div>
                        <img onClick={()=>this.deleteFile(file.name)}  src={deleteBtn} alt="delete" style={{cursor:'pointer'}}/>
                    </div>
                    ))}

                </div>

                <button
                    className="uploadBtn"
                    disabled={selectedFiles.length===0 || somme>5245000000}
                    onClick={this.uploadFiles}
                >
                    Uploader
                </button>
                <ModalCode  show={show} code={code} successcode={success?1:0} onHide={() => this.hideModal()}/>
            </div>
        );
    }

    deleteFile = (name)=>{
        let {selectedFiles}=this.state;
        const newFiles=_.filter(selectedFiles, (file)=> { return file.name !== name; });
        this.setState({selectedFiles:newFiles});

    }

    selectFiles = (event) => {
        if(event.target.files.length > 0){
            //let files = event.target.files.length > 1 ? [...event.target.files] : event.target.files[0];
            const sameFiles=this.verifyIfExist(event.target.files);
            //console.log("sameFiles : ", sameFiles);
            //console.log("Files : ", files);
            this.setState({
                progressInfos: [],
                selectedFiles: sameFiles
            });
        }

    }

    verifyIfExist=(files)=>{
        let arrayFiles=[...files];
        arrayFiles.forEach(element =>{
            console.log(element.name);
            if(element.name.includes('à')){
                console.log('includessss')
            }});

        let selectedFiles = [...this.state.selectedFiles];
        const oldFiles=_.filter(selectedFiles, function(item){
            let element=_.find(arrayFiles, ['name', item.name]);
            return !(!!element);
        });
        return oldFiles.concat(arrayFiles);
    }

    uploadFiles = () => {
        this.setState({loading:true});
        const selectedFiles = this.state.selectedFiles;
        UploadService.upload(selectedFiles).then((response) => {
            this.showModal(response.data.code,true);
        }).catch(()=>{
            this.showModal('',false);
        });
    }

    totalSizeConverter=(aSize)=> {
        if(aSize===0){
            return '0 Mo';
        }
        aSize = Math.abs(parseInt(aSize, 10));
        var def = 1024*1024*1024;
        return (aSize/def).toFixed(2)+' Gb';
    };

    filesSize=()=>{
        const selectedFiles = this.state.selectedFiles;
        let somme= 0;
        for(let i=0; i< selectedFiles.length;i++){
            somme += selectedFiles[i].size;
        }
        return somme;
    }

    showModal = (code,success) => {
        this.setState({loading:false,code:code,success:success,show: true});
    };

    hideModal = () => {
        let success=this.state.success;
        if(success){
            this.setState({selectedFiles:[],show: false,code:'',success:false});
        }else{
            this.setState({show: false,code:''});
        }


    };


}

export default UpdateFiles;
