import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import closeModal from '../../assets/img/close_modal.png';

class ModalCode extends Component {

    render() {
        return(
        <div onClick={e => e.stopPropagation()}>

            <Modal
                {...this.props}
                size="ls"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                {this.renderModal()}
            </Modal>
        </div>
        );
    }

    renderModal=()=>{
        if(this.props.successcode===1){
            return(
                <Modal.Body>
                    <img onClick={this.props.onHide} src={closeModal}
                         className="closeModal" alt="close"/>
                    <div>
                        <p className="popup-txt"> Votre Code est valable 48 heures : </p>

                        <p className="codeTxt">{this.props.code}</p>
                    </div>

                </Modal.Body>
            )
        }
        else if(this.props.successcode===0){
            return (
                <Modal.Body className='errorModal'>
                    <img onClick={this.props.onHide} src={closeModal}
                         className="closeModal" alt="close"/>
                    <div>
                        <p className="popup-txt">Désolé, une erreur est survenue!!!</p>
                    </div>

                </Modal.Body>
            )
        }else if(this.props.successcode===2){
            return (
                <Modal.Body className='errorModal'>
                    <img onClick={this.props.onHide} src={closeModal}
                         className="closeModal" alt="close"/>
                    <div>
                        <p className="popup-txt">Oups, Merci de vérifier votre Code!!!</p>
                    </div>

                </Modal.Body>
            )
        }else{
            return (
                <Modal.Body>
                    <img onClick={this.props.onHide} src={closeModal}
                         className="closeModal" alt="close"/>
                    <div>
                        <p className="popup-txt" style={{marginRight: 10}}> La version mobile est en cours de développement, pour récupérer vos fichiers merci d'utiliser le code sur la version web. </p>

                    </div>

                </Modal.Body>
            )
        }

    }


}

export default ModalCode;
