import logo from '../../assets/img/logo.png';

const Header=()=> {
    return (
        <div className='div-logo'>
          <img src={logo} alt="logo" width={176} height={46}/>
        </div>
    );
}

export default Header;
